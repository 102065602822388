import learnlinkAPI from "./learnlinkAPI";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/app/files";

export async function listFiles(collection: Learnlink.File.DocumentCollectionRef, uid: string): Promise<Learnlink.File.FileReference[]> {
  const fileRes = await learnlinkAPI.axios.get(`${ENDPOINT}/${collection}/${uid}`);
  return fileRes.data;
}

export async function deleteFile(collection: Learnlink.File.DocumentCollectionRef, fileID: string, uid: string): Promise<void> {
  const deleteRes = await learnlinkAPI.axios.delete(`${ENDPOINT}/deleteFile/${collection}/${uid}/${fileID}`);
  return deleteRes.data;
}

export async function uploadFiles(formData: FormData, collection: Learnlink.File.DocumentCollectionRef, uid: string): Promise<Learnlink.File.FileReference[]> {
  const uploadRes = await learnlinkAPI.axios.post(`${ENDPOINT}/upload/${collection}/${uid}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return uploadRes.data;
}

export async function uploadE2EIdentification(uid: string): Promise<void> {
  await learnlinkAPI.axios.post(`app/e2e/identification/${uid}`);
}
