import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";

const ENDPOINT = "/stripe";

export async function createExternalAccount(uid: string, tokenID: string): Promise<Learnlink.Stripe.BankAccount> {
  const stripeRes = await learnlinkAPI.axios.post<Learnlink.Stripe.BankAccount>(`${ENDPOINT}/connect/addBankAccount/${uid}`, { tokenID });
  return stripeRes.data;
}

export async function createStripeCard(uid: string, tokenID: string): Promise<Learnlink.Stripe.Card> {
  const stripeRes = await learnlinkAPI.axios.post<Learnlink.Stripe.Card>(`${ENDPOINT}/customers/addCard/${uid}`, { tokenID });
  return stripeRes.data;
}

export async function getConnectedAccount(uid: string): Promise<Learnlink.Stripe.ConnectedAccountRequirementMessage[]> {
  const stripeRes = await learnlinkAPI.axios.get<Learnlink.Stripe.ConnectedAccountRequirementMessage[]>(`${ENDPOINT}/requirements/${uid}`);
  return stripeRes.data;
}

export async function getStripeAccounts(uid: string): Promise<Learnlink.Stripe.BankAccountView> {
  const stripeRes = await learnlinkAPI.axios.get<Learnlink.Stripe.BankAccountView>(`${ENDPOINT}/connect/externalAccounts/${uid}`);
  return stripeRes.data;
}

export async function getStripeCards(uid: string): Promise<Learnlink.Stripe.CardView> {
  const stripeRes = await learnlinkAPI.axios.get<Learnlink.Stripe.CardView>(`${ENDPOINT}/customers/cards/${uid}`);
  return stripeRes.data;
}

export async function deleteStripeExternalAccount(uid: string, accountID:string): Promise<void> {
  const stripeRes = await learnlinkAPI.axios.delete(`${ENDPOINT}/deleteBankAccount/${uid}/${accountID}`);
  return stripeRes.data;
}

export async function deleteStripeCard(uid: string, cardID: string): Promise<void> {
  const stripeRes = await learnlinkAPI.axios.delete(`${ENDPOINT}/deleteCard/${uid}/${cardID}`);
  return stripeRes.data;
}

export async function setAccountAsDefault(uid: string, bankAccountID: string): Promise<void> {
  const stripeRes = await learnlinkAPI.axios.put(`${ENDPOINT}/setAccountAsDefault/${uid}`, { bankAccountID });

  return stripeRes.data;
}

export async function setCardAsDefault(uid: string, cardID: string): Promise<void> {
  const stripeRes = await learnlinkAPI.axios.put(`${ENDPOINT}/setCardAsDefault/${uid}`, { cardID });

  return stripeRes.data;
}

export async function syncStripeAccounts(uid: string): Promise<void> {
  await learnlinkAPI.axios.get(`${ENDPOINT}/syncExternalAccounts/${uid}`);
}
