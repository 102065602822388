

































































































import ProfilePhoto from "@/components/profile/ProfilePhoto.vue";
import Uploader from "@/components/settings/upload/Uploader.vue";
import formRules from "@/helpers/formRules";
import { useNotifier } from "@/providers/notifier";
import { userModule } from "@/store/modules/user";
import { computed, defineComponent, onMounted, reactive, ref } from "@vue/composition-api";
import handleError from "@/helpers/errors";

export default defineComponent({
  name: "EditProfile",
  components: {
    Uploader,
    ProfilePhoto,
  },
  setup: (props, { emit }) => {
    const vm = reactive({
      aboutText: "",
      interestsText: "",
      loading: false,
      previousWorkText: "",
      sellerNotes: "",
      tagline: "",
      tutorText: "",
    });

    const validForm = ref(false);
    const photoURL = computed(() => userModule.state.profile.photoURL);
    const notify = useNotifier();

    const updateProfile = async(newPhotoURL: string) => {
      await userModule.actions.updateUserProfile({ photoURL: newPhotoURL });
    };

    onMounted(() => {
      vm.aboutText = userModule.state.profile.aboutText;
      vm.interestsText = userModule.state.profile.interestsText;
      vm.previousWorkText = userModule.state.profile.previousWorkText;
      vm.sellerNotes = userModule.state.profile.sellerNotes;
      vm.tagline = userModule.state.profile.tagline;
      vm.tutorText = userModule.state.profile.tutorText;
    });

    async function submit() {
      vm.loading = true;
      if (!validForm.value) {
        notify({
          title: "Noe mangler! 😱",
          message: "Det ser ut til at noen av feltene ikke er fylt ut. Prøv å lagre når alt er utfylt.",
          type: "error",
        });
      }
      else if (!userModule.state.profile.photoApproved) {
        notify({
          title: "Profilbilde mangler! 😱",
          message: "Husk å laste opp et profilbilde. Det bør se noenlunde profesjonelt ut, ha grei oppløsning og vise ansiktet ditt.",
          type: "error",
        });
      }
      else {
        try {
          await userModule.actions.updateUserProfile({
            aboutText: vm.aboutText,
            interestsText: vm.interestsText,
            previousWorkText: vm.previousWorkText,
            tagline: vm.tagline,
            tutorText: vm.tutorText,
            uid: userModule.state.userId,
          });

          notify({
            title: "Informasjonen ble lagret",
            message: "",
            type: "success",
          });

          emit("success");
        }
        catch (e) {
          handleError(e);
          notify({
            title: "Oops",
            message: "Noe gikk galt. Ta kontakt med support om problemet vedvarer",
            type: "error",
          });
        }
      }

      vm.loading = false;
    }

    return {
      formRules,
      photoURL,
      submit,
      updateProfile,
      validForm,
      vm
    };
  },
});
