var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"1"}},[(_vm.file.approved > 0 && _vm.file.rejected === 0)?_c('v-tooltip',{attrs:{"left":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle ")])]}}],null,false,716101747)},[_c('span',[_vm._v("Filen er godkjent. ‍🥳 ")])]):(_vm.file.rejected === 0 && _vm.documentType === 'certificates')?_c('v-tooltip',{attrs:{"left":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle ")])]}}])},[_c('span',[_vm._v("Venter på godkjenning. 😬")])]):_c('v-tooltip',{attrs:{"left":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle ")])]}}])},[_c('span',[_vm._v("Filen er ikke godkjent. 🤯 Det kan skyldes mangelfullt innhold, at dokumentet ikke når kravene for godkjenning, eller problemer med opplastingen.")])])],1),_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"7"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":_vm.file.url,"target":"_blank"}},'a',attrs,false),on),[_vm._v(" "+_vm._s(_vm.fileName)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.file.name))])])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"text":"","color":"red","loading":_vm.isLoading,"data-test-id":"delete-file-button"},on:{"click":function($event){return _vm.remove()}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1),_c('v-divider')],1)}
var staticRenderFns = []

export { render, staticRenderFns }