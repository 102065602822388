























































































import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import { deleteFile } from "@/api/fileService";
import Learnlink from "@learnlink/interfaces";
import { userModule } from "@/store/modules/user";
import { useNotifier } from "@/providers/notifier";

export default defineComponent({
  name: "FileTemplate",
  props: {
    documentType: {
      type: String as PropType<Learnlink.File.DocumentCollectionRef>,
      required: true,
    },
    file: {
      type: Object as PropType<Learnlink.File.FileReference>,
      required: true,
    },
  },
  setup: (props, { emit }) => {
    const isLoading = ref(false);
    const notify = useNotifier();
    const fileName = computed(() => {
      return props.file.name.length < 12 ? props.file.name : props.file.name.slice(0, 15) + "...";
    });

    const remove = async() => {
      isLoading.value = true;
      try {
        await deleteFile(props.documentType, props.file.uid, userModule.state.userId);
        notify({
          title: "Filen ble slettet!",
          message: "",
          type: "success",
        });
      }
      catch (e) {
        notify({
          title: "Oops",
          message: "Noe gikk galt, ta kontakt med support om problemet vedvarer",
          type: "error",
        });
      }

      emit("file-deleted", props.file.uid);
      isLoading.value = false;
    };

    return {
      fileName,
      isLoading,
      remove,
    };
  },
});
