






















































































import { defineComponent, PropType, ref } from "@vue/composition-api";
import { listFiles, uploadFiles } from "@/api/fileService";
import { uploadProfilePhoto } from "@/api/userService";
import { useNotifier } from "@/providers/notifier";
import { userModule } from "@/store/modules/user";
import FileTemplate from "./FileTemplate.vue";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import useAsyncData from "@/hooks/useAsyncData";
import axios from "axios";

interface HTMLInputEvent extends Event {
  dataTransfer: HTMLInputElement & DataTransfer;
  target: HTMLInputElement & EventTarget;
}

export default defineComponent({
  name: "Uploader",
  components: { FileTemplate },
  props: {
    documentType: {
      type: String as PropType<Learnlink.File.DocumentCollectionRef>,
      required: true,
    },
  },
  setup: (props, { emit }) => {
    const isUploading = ref(false);
    const { isLoading, data: uploadedFiles } = useAsyncData(() =>
      listFiles(props.documentType, userModule.state.userId)
    );
    const notify = useNotifier();

    const handleFileInput = (e: HTMLInputEvent) => {
      const fieldName = e.target.name;
      const fileList = e.target.files;
      const formData = new FormData();

      if (!fileList || !fileList.length) return;

      Array
        .from(Array(fileList.length).keys())
        .forEach(x => {
          formData.append(fieldName, fileList[x], fileList[x].name);
        });

      save(formData);
    };

    function handleDelete(deletedFileID: string) {
      if (uploadedFiles.value) {
        const allFiles = uploadedFiles.value;
        uploadedFiles.value = allFiles.filter((file) => file.uid !== deletedFileID);
      }
    }

    function policeRecordHelp() {
      window.open("https://www.notion.so/learnlink/Hvordan-f-r-jeg-politiattest-fb106b95ec494389921961e120e531f1", "_blank");
    }

    async function save(formData: FormData) {
      try {
        isUploading.value = true;
        const fileRes = props.documentType === "profilePhoto"
          ? await uploadProfilePhoto(userModule.state.userId, formData)
          : await uploadFiles(formData, props.documentType, userModule.state.userId);
        if (uploadedFiles.value && typeof fileRes !== "string") {
          uploadedFiles.value = uploadedFiles.value.concat(fileRes);
        }

        emit("uploadComplete", fileRes);

        notify({
          title: fileRes.length > 1 ? "Filene ble lastet opp!" : "Filen ble lastet opp!",
          message: "",
          type: "success",
        });
      }
      catch (e) {
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 413) {
            notify({
              title: "💥",
              message: "Filen var så stor at den sprengte. Prøv en mindre fil.",
              type: "error",
            });
          }
          else if (e.response?.status === 400) {
            notify({
              title: "🔍",
              message: "Vi klarte ikke å lese informasjonen på bildet. Sørg for at teksten er tydelig og at " +
                "bildet er skarpt. Prøv igjen hvis problemet vedvarer.",
              type: "error",
            });
          }
          else if (e.response?.status === 406) {
            notify({
              title: "🔍",
              message: "Informasjonen på bildet stemmer ikke overens med informasjonen på din profil. Det kan komme av " +
                "skrivefeil eller utydelig bilde. Dobbeltsjekk at informasjonen på bildet er tydelig " +
                "og at profilinformasjonen stemmer overens med informasjonen på bildet.",
              type: "error",
            });
          }
          else {
            handleError(e);
            notify({
              title: "Oops",
              message: "Noe gikk galt, ta kontakt med support om problemet vedvarer",
              type: "error",
            });
          }
        }
      }
      uploadedFiles.value = await listFiles(props.documentType, userModule.state.userId);
      isUploading.value = false;
    }

    return {
      handleDelete,
      handleFileInput,
      isLoading,
      isUploading,
      policeRecordHelp,
      uploadedFiles,
    };
  },
});

